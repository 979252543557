import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Image, Input, message, Modal, PageHeader, Popconfirm, Popover, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";

function GalleryView(){

    const [user, setUser] = useState({});
    const [data, setData] = useState([]);
    const data_list = data.map(e=>(
        <div className="col-md-2 p-1">
            <Popover title={e['nama']} content={<Popconfirm title='Konfirmasi hapus foto?' onConfirm={()=>{ 
                axios.post(Globalset.app_url+'/gallery_del', serialize({id: e['id']}), Globalset.app_authentic).then(()=>{
                    axios(Globalset.app_url+'/gallery', Globalset.app_authentic).then(res2=>{
                        setData(res2.data);
                    });
                });
            }}><Button size="small"><FontAwesomeIcon icon={faTrash} /> Hapus</Button></Popconfirm>}>
                <Image src={Globalset.app_assets+'/gallery/512/'+e['file']} preview={{src: Globalset.app_assets+'/gallery/1280/'+e['file']}} />
            </Popover>
        </div>
    ));
    const [mod, setMod] = useState(false);
    const modref = useRef();

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const finish = e => {
        let data = {
            nama: e['nama'],
            file: e['filename']['file']['response'],
            idcreate: user['id']
        }
        setMod(false);
        axios.post(Globalset.app_url+'/gallery_add', serialize(data), Globalset.app_authentic).then(res=>{
            if(res.data==='success'){
                message.success('Unggah berhasil !');
                axios(Globalset.app_url+'/gallery', Globalset.app_authentic).then(res=>{
                    setData(res.data);
                });
            } else {
                message.error('Terjadi gangguan !');
            }
        });
    }

    useEffect(()=>{

        sessionService.loadUser().then(e=>{
            setUser(e);
        });

        axios(Globalset.app_url+'/gallery', Globalset.app_authentic).then(res=>{
            setData(res.data);
        });
    }, []);

    return(
        <>
        <PageHeader title='Galeri' />
        <Card>
            <Button onClick={()=>{ setMod(true) }} type='primary' >Unggah</Button>
            <br /><br />
            <Modal title='Unggah Galeri' visible={mod}  onCancel={()=>{ setMod(false) }} onOk={()=>{ modref.current.submit() }} >
                <Form ref={modref} onFinish={finish} layout='vertical'>
                    <Form.Item label='Keterangan Foto' name='nama'>
                        <Input />
                    </Form.Item>
                    <Form.Item name='filename' rules={[{required: true}]}>
                        <Upload {...props} maxCount={1}>
                            <Button>Pilih File</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="row">
                {data_list}
            </div>
        </Card>
        </>
    );
}

export default GalleryView;