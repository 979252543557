
import Globalset from "./Globalset";
import AboutHistoryView from "./views/AboutHistoryView";
import AboutStructureView from "./views/AboutStructureView";
import AboutView from "./views/AboutView";
import AboutVisionView from "./views/AboutVisionView";
import BaseView from "./views/BaseView";
import CalculationView from "./views/CalculationView";
import CounselorView from "./views/CounselorView";
import EventAddView from "./views/EventAddView";
import EventImageView from "./views/EventImageView";
import EventInfoView from "./views/EventInfoView";
import EventView from "./views/EventView";
import GalleryView from "./views/GalleryView";
import JournalAddView from "./views/JournalAddView";
import JournalView from "./views/JournalView";
import NewsAddView from "./views/NewsAddView";
import NewsInfoView from "./views/NewsInfoView";
import NewsView from "./views/NewsView";
import UserView from "./views/UserView";
import UserLoginView from "./views/UserLoginView";

import { faImage, faNewspaper } from "@fortawesome/free-regular-svg-icons";
import { faBook, faCalculator, faCalendarAlt, faInfoCircle, faList, faUser, faUserDoctor } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Layout, Menu, message, PageHeader, Popconfirm } from "antd";
import { Link, Route, Routes } from "react-router-dom";
import { sessionService } from "redux-react-session";
import { useSelector } from 'react-redux';
import { isCursorAtEnd } from "@testing-library/user-event/dist/utils";
import { useEffect } from "react";
import moment from "moment";

const { Header, Sider, Content } = Layout;

function App(){

    const user = useSelector(state => state.session.user);

    const logout = () => {
        sessionService.deleteUser().then(()=>{
            message.success('Anda telah Logout!');
        });
    }

    useEffect(()=>{

        sessionService.loadUser().then(e=>{
            e['app_name'] = Globalset.app_name;
            e['lastlogin'] = moment().format('YYYY-MM-DD HH:mm:ss');
            sessionService.saveUser(e);
        });

        if(user.id!=null){
            let now = moment().diff(moment(user['lastlogin'], 'YYYY-MM-DD HH:mm:ss'), 'hour');
            if(now>10){
                sessionService.deleteUser().then(()=>{
                    message.warn('Sesi anda sudah habis, silahkan login kembali !');
                });
            }
        }
    }, []);

    return(
        user['id']==undefined ?
        <UserLoginView /> :
        <Layout style={{height: '100vh'}}>
            <Header style={{backgroundColor: Globalset.app_primary, flexDirection: 'row'}}>
                <PageHeader title={ <span style={{color: 'white'}}>{Globalset.app_name}</span>} style={{height: 50}} extra={[<Button type="ghost"><Link to='/user'><FontAwesomeIcon color="white" icon={faUser} /></Link></Button>, <Popconfirm title="Yakin ingin logout?" onConfirm={logout}><Button>Logout</Button></Popconfirm>]} />
            </Header>
            <Layout>
                <Sider theme="light" style={{overflow: 'auto'}}>
                    <Menu mode="inline" defaultSelectedKeys={['1']}>
                        <Menu.Item key={'1'} icon={<FontAwesomeIcon icon={faInfoCircle} />}><Link to='/'>Informasi</Link></Menu.Item>
                        <Menu.Item key={'2'} icon={<FontAwesomeIcon icon={faNewspaper} />}><Link to='/news'>Berita</Link></Menu.Item>
                        <Menu.Item key={'3'} icon={<FontAwesomeIcon icon={faCalendarAlt} />}><Link to='/event'>Kegiatan</Link></Menu.Item>
                        <Menu.Item key={'4'} icon={<FontAwesomeIcon icon={faImage} />}><Link to='/gallery'>Galeri</Link></Menu.Item>
                        <Menu.Item key={'5'} icon={<FontAwesomeIcon icon={faBook} />}><Link to='/journal'>Jurnal</Link></Menu.Item>
                        <Menu.Item key={'6'} icon={<FontAwesomeIcon icon={faCalculator} />}><Link to='/calculation'>Penghitungan</Link></Menu.Item>
                        <Menu.Item key={'7'} icon={<FontAwesomeIcon icon={faUserDoctor} />}><Link to='/counselor'>Penasihat</Link></Menu.Item>
                        <Menu.SubMenu title='Tentang HAKLI' icon={<FontAwesomeIcon icon={faList} /> }>
                            <Menu.Item><Link to='/about'>Tentang/Profile</Link></Menu.Item>
                            <Menu.Item><Link to='/about/history'>Sejarah</Link></Menu.Item>
                            <Menu.Item><Link to='/about/vision'>Visi dan Misi</Link></Menu.Item>
                            <Menu.Item><Link to='/about/structure'>Susunan Pengurus</Link></Menu.Item>
                        </Menu.SubMenu>
                    </Menu>
                </Sider>
                <Content style={{padding: 20, overflow: 'auto'}}>
                 
                    <Routes>
                        <Route path="/" element={<BaseView />} />
                        <Route path="/news" element={<NewsView />} />
                        <Route path="/news/add" element={<NewsAddView />} />
                        <Route path="/news/info/:id" element={<NewsInfoView />} />

                        <Route path="/event" element={<EventView />} />
                        <Route path="/event/add" element={<EventAddView />} />
                        <Route path="/event/info/:id" element={<EventInfoView />} />
                        <Route path="/event/image/:id" element={<EventImageView />} />

                        <Route path="/gallery" element={<GalleryView />} />
                        <Route path="/user" element={<UserView />} />

                        <Route path="/journal" element={<JournalView />} />
                        <Route path="/journal/add" element={<JournalAddView />} />

                        <Route path="/calculation" element={<CalculationView />} />
                        <Route path="/counselor" element={<CounselorView />} />

                        <Route path="/about" element={<AboutView />} />
                        <Route path="/about/history" element={<AboutHistoryView />} />
                        <Route path="/about/vision" element={<AboutVisionView />} />
                        <Route path="/about/structure" element={<AboutStructureView />} />

                    </Routes>
                </Content>
            </Layout>
        </Layout>
    );
}

export default App;