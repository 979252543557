import { Button, PageHeader, Select, Space, Table } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Globalset from "../Globalset";

const { Option } = Select;

function EventView(){
    const [loading, setLoading] = useState(true);
    const [idkategori, setIdkategori] = useState(undefined);
    const [kegiatan, setKegiatan] = useState([]);
    const kolom = [
        {title: 'Tercatat', dataIndex: 'created_at'},
        {title: 'Kategori', dataIndex: 'kategori'},
        {title: 'Judul', render: e=>(
            <span style={{fontWeight: 'bold'}}>{e['judul']}</span>
        )},
        {title: 'Foto', render: e=>(
            <Button size="small" type="dashed"><Link to={'/event/image/'+e['id']}>Upload Foto</Link></Button>
        )},
        {title: 'Opsi', render: e=>(
            <Button size='small' type="primary"><Link to={'/event/info/'+e['id']}>Lihat</Link></Button>
        )}
    ]

    const [kategori, setKategori] = useState([]);
    const kategori_list = kategori.map(e=>(
        <Option key={e['id']}>{e['nama']}</Option>
    ));

    const find = () => {
        let kat = idkategori;
        if(idkategori===undefined){
            kat = '';
        }
        axios(Globalset.app_url+'/event?idcategory='+kat, Globalset.app_authentic).then(res=>{
            setKegiatan(res.data);
            setLoading(false);
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/event_category', Globalset.app_authentic).then(res=>{
            setKategori(res.data);
            find();
        });
    }, []);

    useEffect(()=>{
        find();
    }, [idkategori]);

    return(
        <>
        <PageHeader title='Agenda Kegiatan' extra={<Button type="primary"><Link to='/event/add'>Buat Agenda Acara</Link></Button>} />
        <Space direction="vertical" style={{width: '100%'}}>
            <Select style={{width: '30%'}} placeholder='Filter Kategori' onChange={e=>{ setIdkategori(e) }} allowClear>{kategori_list}</Select>
            <Table loading={loading} dataSource={kegiatan} columns={kolom} />
        </Space>
        
        </>
    );
}

export default EventView;