
class Globalset {

    static app_name = 'Admin HAKLI';
    static app_primary = '#00aa55';
    /*static app_url = 'http://localhost/hakli/public/api';
    static app_assets = 'http://localhost/hakli/public/assets';*/
    
    static app_url = 'https://pphakli.org/api';
    static app_assets = 'https://pphakli.org/assets';

    static app_key_tinymce = 'yixavei729smmrc2vpoa2eaih04r4h1n40ijg7tv94cny2pc';

    static app_auth_user = 'afandi'
    static app_auth_pass = '@hm4d@FanDi!'
    static app_authentic = {auth: {username: this.app_auth_user, password: this.app_auth_pass}}

}

export default Globalset;