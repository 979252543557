
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, Form, Image, Input, message, PageHeader, Switch, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";

function NewsAddView(){

    const nav = useNavigate();

    const [aktif, setAktif] = useState(true);
    const [file, setFile] = useState('');
    const [user, setUser] = useState({});
    const [lod, setLod] = useState(false);

    const isi = useRef(null);

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setFile(info.file.response);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    useEffect(()=>{
        sessionService.loadUser().then(e=>{
            setUser(e);
        });
    }, []);

    const finish = e => {
        e['aktif'] = aktif;
        e['isi'] = isi.current.getContent();
        e['file'] = file;
        e['idcreate'] = user['id'];
      
        setLod(true);
        axios.post(Globalset.app_url+'/news_add', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            if(res.data==='success'){
                message.success('Buat berita baru berhasil !');
                setTimeout(()=>{
                    nav('/news');
                }, 1000);
            } else {
                message.error('Terjadi gangguan !');
            }
        });
    }

    return(<>
        <PageHeader title='Buat Berita Baru' onBack={()=>{ nav(-1) }} />
        <Card>
            <Form onFinish={finish}>
                <Form.Item label='Judul' name='judul' rules={[{required: true}]}>
                    <Input />
                </Form.Item>
                <Form.Item label='Status'>
                    <Switch checkedChildren="Aktif" unCheckedChildren="Tidak Aktif" checked={aktif} onChange={(e)=>{ setAktif(e) }} />
                </Form.Item>
                <Form.Item label='Foto'>
                    {file!==''?
                    <><Image height={128} src={Globalset.app_assets+'/tmp/'+file} /><br/></>
                    :null}
                    <Upload {...props} maxCount={1}>
                        <Button size="small">Pilih Foto</Button>
                    </Upload>
                </Form.Item>
                <Form.Item label='Video Link Youtube' name='youtube'>
                    <Input addonBefore='https://www.youtube.com/watch?v=' placeholder="kode" />
                </Form.Item>
                <Form.Item>
                   <Editor onInit={(evt, editor)=> isi.current = editor } apiKey={Globalset.app_key_tinymce} />
                </Form.Item>
                <Form.Item>
                    <Button loading={lod} htmlType="submit" type="primary"><><FontAwesomeIcon icon={faSave} /> Simpan</></Button>
                </Form.Item>
            </Form>
        </Card>
    </>);
}

export default NewsAddView;