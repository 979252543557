
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Image, Input, Layout, message, Space, Typography } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useState } from "react";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";
import moment from "moment";

const { Content } = Layout;
const { Title } = Typography;

function UserLoginView(){

    const [lod, setLod] = useState(false);
    const finish = e => {
        setLod(true);
        axios.post(Globalset.app_url+'/user_login', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            let json = res.data;
            if(json['status']===1){
                message.success(json['message']);
                json['user']['app_name'] = Globalset.app_name;
                json['user']['lastlogin'] = moment().format('YYYY-MM-DD HH:mm:ss');
                sessionService.saveUser(json['user']);
            } else {
                message.error(json['message']);
            }
        });
    }

    return(
        <Layout style={{height: '100vh'}}>
            <Content style={{backgroundColor: Globalset.app_primary}}>
                <br /><br /><br />
                <div className="container">
                    <div className="row">
                        <div className="col-md-3">
                            
                        </div>
                        <div className="col-md-6">
                            <div style={{textAlign: 'center'}}>
                                <Image src='/icon.png' />
                                <br /><br />
                                <Title level={1} style={{color: 'white'}}>{Globalset.app_name}</Title>
                                
                            </div>
                            <Card title='Login'>
                                <Form layout="vertical" onFinish={finish}>
                                    <Form.Item label="Username" name='username' rules={[{required: true}]}>
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Password" name='password' rules={[{required: true}]}>
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button loading={lod} htmlType="submit" type="primary"><Space><FontAwesomeIcon icon={faSignInAlt} /> Login</Space></Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </div>
                        <div className="col-md-3">
                            
                        </div>
                    </div>
                </div>
            </Content>
        </Layout>
    );
}

export default UserLoginView;