import { Button, Card, Form, Input, message, PageHeader, Space } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";

function UserView(){

    const [user, setUser] = useState({});
    const referensi = useRef();
    const nav = useNavigate();

    const updatenama = e => {
        e['id'] = user['id'];
        delete e['username'];
        axios.post(Globalset.app_url+'/user_update', serialize(e), Globalset.app_authentic).then(res=>{
            if(res.data==='success'){
                let data = user;
                data['nama'] = e['nama'];
                sessionService.saveUser(data).then(e=>{
                    message.success('User berhasil diupdate !');
                });
            } else {
                message.error('Terjadi kesalahan !');
            }
        });
    }

    const updatepass = e => {
        e['id'] = user['id'];
        axios.post(Globalset.app_url+'/user_password_update', serialize(e), Globalset.app_authentic).then(res=>{
            let pesan = res.data;
            if(pesan!=='success'){
                message.error(pesan);
            } else {
                message.success('Update Password berhasil !');
            }
        });
    }

    useEffect(()=>{
        sessionService.loadUser().then(e=>{
            setUser(e);
            referensi.current.setFieldsValue({
                username: e['username'],
                nama: e['nama']
            });
        });
    }, []);

    return(
        <>
        <PageHeader title='User' onBack={()=>{ nav(-1) }} />
        <Space direction="vertical" style={{width: '100%'}}>
            <Card title='Informasi Pribadi'>
                <Form layout="vertical" ref={referensi} onFinish={updatenama}>
                    <Form.Item label='Username' name='username' >
                        <Input readOnly/>
                    </Form.Item>
                    <Form.Item label='Nama' name='nama' rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">Simpan</Button>
                    </Form.Item>
                </Form>
            </Card>
            <Card title='Ganti Password'>
                <Form layout="vertical" onFinish={updatepass}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Item label='Password Baru' name='password' rules={[{required: true}]}>
                                <Input.Password />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label='Ketik Password Lama' name='password_old' rules={[{required: true}]}>
                                <Input.Password />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">Simpan Password</Button>
                    </Form.Item>
                </Form>
            </Card>
        </Space>
        </>
    );
}

export default UserView;