
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, PageHeader, Popconfirm, Space, Switch, Table, Tag } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Globalset from "../Globalset";

function NewsView(){

    const [loading, setLoading] = useState(true);
    const [berita, setBerita] = useState([]);
    const kolom = [
        {title: 'Tercatat', dataIndex: 'created_at'},
        {title: 'Judul', render: e=>(
            <span style={{fontWeight: 'bold'}}>{e['judul']}</span>
        )},
        {title: 'Highlight', render:(e,i,s)=>(
            <Switch checked={ e['highlight'] } onChange={f=>{ berita[s]['highlight'] = f; setBerita([...berita]); statusberita(e['id'], f) }} />
        )},
        {title: 'Status', render: e=>(
            e['aktif']==='true' ?
            <Tag color='green'>Aktif</Tag> 
            :
            <Tag color='red'>Tidak</Tag> 
        )},
        {title: 'Opsi', render: (e=>(
            <Space>
                <Button type="primary" size="small"><Link to={'/news/info/'+e['id']}>Lihat</Link></Button>
                <Popconfirm title='Hapus berita ini?' onConfirm={ ()=>{ hapus(e['id']) } }><Button size="small"><FontAwesomeIcon icon={faTrash} /> </Button></Popconfirm>
            </Space>
        ))},
    ]

    const hapus = id => {
        axios.post(Globalset.app_url+'/news_del', serialize({'id': id}), Globalset.app_authentic).then(res=>{
            if(res.status===200){
                message.success('Hapus behasil !');
                fill();
            }
        });
    }

    const statusberita = (e, f) => { 
        let data = {
            id: e,
            highlight: f
        }
        axios.post(Globalset.app_url+'/news_active', serialize(data), Globalset.app_authentic).then(res=>{
            console.log(res.data);
        });
    }

    const fill = () => {
        setLoading(true);
        axios(Globalset.app_url+'/news', Globalset.app_authentic).then(res=>{
            let json = res.data;
            for(let a=0; a<json.length; a++){
                json[a]['highlight'] = JSON.parse(json[a]['highlight']);
            }
            setBerita(json);
            setLoading(false);
        });
    }

    useEffect(fill, []);

    return(
        <>
            <PageHeader title='Berita' extra={<Button type="primary"><Link to='/news/add'>Buat Berita</Link></Button>} />
            <Table loading={loading} dataSource={berita} columns={kolom} />
        </>
    );
}

export default NewsView;
