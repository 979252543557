import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import { Alert, Button, Card, Image, message, PageHeader, Popconfirm, Space, Tooltip, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import Globalset from "../Globalset";

function AboutStructureView(){

    const isi = useRef();
    const [isikontent, setIsikontent] = useState('');
    const [gambar, setGambar] = useState('');
    const simpan = () => {
        let data = {
            id: 4,
            nilai: isi.current.getContent()
        }
        axios.post(Globalset.app_url+'/template_update', serialize(data), Globalset.app_authentic).then(res=>{
            if(res.data==='success'){
                message.success('Update berhasil !');
            } else {
                message.error('Terjadi kesalahan !');
            }
        });
    }

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            simpangambar(info.file.response);
            console.log(info.file.response);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };

    const simpangambar = (file) => {
        let data = {
            id: 5,
            nilai: file
        }
        axios.post(Globalset.app_url+'/template_update', serialize(data), Globalset.app_authentic).then(res=>{
            setGambar(file);
        });
    }

    const hapusgambar = () => {
        let data = {
            id: 5,
            nilai: ''
        }
        axios.post(Globalset.app_url+'/template_update', serialize(data), Globalset.app_authentic).then(res=>{
            setGambar('');
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/template_info?id=4', Globalset.app_authentic).then(res=>{;
            let json = res.data;
            setIsikontent(json['nilai']);
            axios(Globalset.app_url+'/template_info?id=5', Globalset.app_authentic).then(res2=>{;
                let json2 = res2.data;
                setGambar(json2['nilai']);
            });
        });
    }, []);

    return(
        <>
        <PageHeader title='Susunan Pengurus' />
        <Space direction="vertical" className="w-100">
            <Card>
                <Space direction="vertical" style={{width: '100%'}}>
                    {gambar!=='' ?
                    <Space>
                    <Image src={Globalset.app_assets+'/structure/'+gambar} height={250} />
                        <Popconfirm title='Hapus gambar ini?' onConfirm={hapusgambar}>
                            <Button>
                                <FontAwesomeIcon icon={faTrash} color='red' />
                            </Button>
                        </Popconfirm>
                    </Space>
                    : <Alert message='Gambar kosong !' type="warning" /> }
                    <Upload maxCount={1} {...props}>
                        <Button><><FontAwesomeIcon icon={faUpload} /> Pilih Gambar</></Button>
                    </Upload>
                </Space>
            </Card>
            <Card>
                <Space direction="vertical" style={{width: '100%'}}>
                    <Editor initialValue={isikontent} onInit={(evt, editor)=> isi.current = editor } apiKey={Globalset.app_key_tinymce} />
                    <Button type="primary" onClick={simpan}>Simpan</Button>
                </Space>
            </Card>
        </Space>
        </>
    );
}

export default AboutStructureView;