import { Button, Input, InputNumber, message, PageHeader, Space, Table } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import Globalset from "../Globalset";


function CalculationView(){

    const [hitung, setHitung] = useState([]);
    const kolom = [
        {title: 'Nama', render: (e,i,s)=>(
            <Input value={e['nama']} onChange={ (f) => { hitung[s]['nama'] = f.target.value; setHitung([...hitung]); } } />
        )},
        {title: 'Nilai', render: (e,i,s)=>(
            <Input value={e['nilai']} onChange={ (f) => { hitung[s]['nilai'] = f.target.value; setHitung([...hitung]); } } />
        )}
    ]

    const finish = () => {
        let data = {
            data: JSON.stringify(hitung)
        }

        axios.post(Globalset.app_url+'/calculation_update', serialize(data), Globalset.app_authentic).then(res=>{
            message.success('Update berhasil !');
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/calculation', Globalset.app_authentic).then(res=>{
            setHitung(res.data);
        });
    }, []);

    return(
        <>
        <PageHeader title='Penghitungan' />
        <Space direction="vertical" style={{width: '100%'}}>
            <Table dataSource={hitung} columns={kolom} pagination={false} />
            <Button type="primary" onClick={finish}>Simpan</Button>
        </Space>
        </>
    );
}

export default CalculationView;