import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, message, PageHeader, Popconfirm, Space, Table } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Globalset from "../Globalset";

function JournalView(){

    const [data, setData] = useState([]);
    const kolom = [
        {title: 'Volume', dataIndex: 'volume'},
        {title: 'Tahun / Bulan', render: e=>(
            e['tahun'] + ' - ' + e['bulan']
        )},
        {title: 'Editor', dataIndex: 'editor'},
        {title: 'Tercatat', dataIndex: 'tercatat'},
        {title: 'Opsi', render: e=>(
            <Space>
                <Button size="small"><a target='_blank' href={Globalset.app_assets+'/journal/'+e['filename']}><FontAwesomeIcon icon={faDownload} /> Unduh</a></Button>
                <Popconfirm title='Yakin hapus jurnal ini?' onConfirm={()=>{ hapus(e['id']) }}><Button size="small"><FontAwesomeIcon color="red" icon={faTrash} /></Button></Popconfirm>
            </Space>
        )},
    ]

    const hapus = e => {
        axios.post(Globalset.app_url+'/journal_del', serialize({id: e}), Globalset.app_authentic).then(res=>{
            message.success('Hapus berhasil !');
            setTimeout(()=>{
                fill();
            }, 1000);
        });
    }

    const fill = () => {
        axios(Globalset.app_url+'/journal', Globalset.app_authentic).then(res=>{
            setData(res.data);
        });
    }

    useEffect(()=>{
        fill();
    }, []);

    return(
        <>
        <PageHeader title='Jurnal' extra={<Button type="primary"><Link to='/journal/add'>Buat Baru</Link></Button>}/>
        <Table dataSource={data} columns={kolom} pagination={false} />
        </>
    );
}

export default JournalView;