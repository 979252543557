import { faImage, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Input, Image, message, Modal, PageHeader, Space, Table, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import Globalset from "../Globalset";

function CounselorView(){

    const [data, setData] = useState([]);
    const kolom = [
        {title: 'Foto', render: e=>(
            <>
                <Space>
                    {e['foto']!=='' ?  <Image height={64} src={Globalset.app_assets+'/counselor/'+e['foto']} /> : null}
                    <Button size="small" onClick={()=>{ unggahfoto(e['id']) }}><><FontAwesomeIcon icon={faUpload} /> Upload</></Button>
                </Space>
            </>
        )},
        {title: 'Nama', render: (e,i,s)=>(<Input value={e['nama']} onChange={f=>{ data[s]['nama'] = f.target.value; setData([...data]); }} />)},
        {title: 'Jabatan', render: (e,i,s)=>(<Input value={e['jabatan']} onChange={f=>{ data[s]['jabatan'] = f.target.value; setData([...data]); }} />)},
        {title: 'Kutipan', render: (e,i,s)=>( <Input.TextArea value={e['kutipan']} onChange={f=>{ data[s]['kutipan'] = f.target.value; setData([...data]); }} />)},
        {title: 'Opsi', render: (e,i,s)=>(
            <Button type="primary" onClick={()=>{ simpan(s) }}>Simpan</Button>
        )}
    ]

    const [mod, setMod] = useState(false);
    const modref = useRef();

    const simpan = e => {
        let dt = {
            id: data[e]['id'],
            nama: data[e]['nama'],
            jabatan: data[e]['jabatan'],
            kutipan: data[e]['kutipan'],
        }
        axios.post(Globalset.app_url+'/counselor_update', serialize(dt), Globalset.app_authentic).then(res=>{
            message.success('Update data berhasil !');
        });
    }

    const modfinish = e => {
        axios.post(Globalset.app_url+'/counselor_add', serialize(e), Globalset.app_authentic).then(res=>{
            setMod(false);
            if(res.data==='success'){
                message.success('Buat Penasihat baru berhasil !');
                isi();
            } else {
                message.error('Terjadi kesalahan !');
            }
        });
    }

    const [id, setId] = useState(undefined);
    const [filename, setFilename] = useState('');
    const [modfoto, setModfoto] = useState(false);
    const unggahfoto = (id) => {
        setId(id);
        setModfoto(true);
    }

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
            setFilename(info.file.response);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
    };

    const isi = () => {
        axios(Globalset.app_url+'/counselor', Globalset.app_authentic).then(res=>{
            setData(res.data);
        });
    }

    const updatefoto = () => {
        if(filename!==''){
            setModfoto(false);
            let data = {
                id: id,
                foto: filename
            }
            axios.post(Globalset.app_url+'/counselor_image', serialize(data), Globalset.app_authentic).then(res=>{
                if(res.data==='success'){
                    message.success('Unggah foto berhasil !');
                    setId(undefined);
                    setFilename('');
                    isi();
                } else {
                    message.error('Terjadi kesalahan !');
                }
            });
        }
    }

    useEffect(()=>{
        isi();
    }, []);

    return(
        <>
        <PageHeader title='Penasihat' />
        <Space direction="vertical" style={{width: '100%'}}>
            <Table dataSource={data} columns={kolom} pagination={false} />
            <Button type="primary" onClick={()=>{ setMod(true) }}>Buat Baru</Button>
            <Modal visible={mod} title="Buat Penasihat Baru" onOk={()=>{ modref.current.submit() }} onCancel={()=>{ setMod(false) }}>
                <Form ref={modref} onFinish={modfinish}>
                    <Form.Item label='Nama' name='nama' rules={[{required: true}]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='Kutipan' name='kutipan' rules={[{required: true}]}>
                        <Input.TextArea />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal visible={modfoto} title='Unggah Foto' onCancel={()=>{ setModfoto(false) }} onOk={()=>{ updatefoto() }} >
                <Upload {...props} maxCount={1}>
                    <Button>Pilih Foto</Button>
                </Upload>
            </Modal>
        </Space>
        </>
    );
}

export default CounselorView;