import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, Input, InputNumber, message, PageHeader, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Globalset from "../Globalset";

function JournalAddView(){

    const nav = useNavigate();

    const [filename, setFilename] = useState('');

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setFilename(info.file.response);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const finish = e => {
        e['filename'] = filename;
        if(filename!==''){
            axios.post(Globalset.app_url+'/journal_add', serialize(e), Globalset.app_authentic).then(res=>{
                let json = res.data;
                if(json==='success'){
                    message.success('Input Jurnal berhasil !');
                    setTimeout(()=>{
                        nav(-1);
                    }, 1000);
                } else {
                    message.error('Terjadi kesalahan !');
                }
            });
        }
    }

    return(
        <>
        <PageHeader title='Buat Jurnal Baru' onBack={()=>{ nav(-1) }} />
        <Card>
            <Form layout="vertical" onFinish={finish}>
                <Form.Item label='Volume' name='volume'>
                    <Input />
                </Form.Item>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item label='Bulan' name='bulan'>
                            <InputNumber className="w-100"/>
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item label='Tahun' name='tahun'>
                            <InputNumber className="w-100" />
                        </Form.Item>
                    </div>
                </div>
                <Form.Item label='Editor' name='editor'>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Upload {...props} maxCount={1}>
                        <Button><><FontAwesomeIcon icon={faFilePdf} /> Pilih File PDF</></Button>
                    </Upload>
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
        </>
    );
}

export default JournalAddView;