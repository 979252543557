import { faCamera, faInfo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, Form, Image, Input, message, PageHeader, Popconfirm, Space, Switch, Tag, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Globalset from "../Globalset";

function NewsInfoView(){

    const param = useParams();
    const nav = useNavigate();
    const isi = useRef();
    const [isikonten, setIsikonten] = useState('');
    const [info, setInfo] = useState({});
    const referensi = useRef();
    const [filename, setFilename] = useState('');
    const [filelama, setFilelama] = useState('');
    const [aktif, setAktif] = useState(false);
    const [lod, setLod] = useState(false);

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                console.log(info.file.response);
                setFilename(info.file.response);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const finish = e => {
        e['id'] = param.id;
        e['aktif'] = aktif;
        e['isi'] = isi.current.getContent();
        e['file'] = filename;
        
        setLod(true);
        axios.post(Globalset.app_url+'/news_edit', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            if(res.data==='success'){
                message.success('Update berhasil !');
            } else {    
                message.error('Terjadi gangguan !');
            }
        });
    }

    const hapusgambar = () => {
        axios.post(Globalset.app_url+'/news_image_del', serialize({'id': param.id}), Globalset.app_authentic).then(res=>{
            fill();
        }).catch(err=>{ message.error(err.message); });
    }

    const fill = () => {
        axios(Globalset.app_url+'/news_info?id='+param.id, Globalset.app_authentic).then(res=>{
            let json = res.data;
            setInfo(json);
            setFilename(json['file']);
            setFilelama(json['file']);
            setAktif(JSON.parse(json['aktif']));
            setIsikonten(json['isi']);

            referensi.current.setFieldsValue({
                judul: json['judul'],
                youtube: json['youtube']
            });
        });
    }

    useEffect(fill, []);

    return(
        <>
        <PageHeader title='Ubah Informasi Berita' onBack={()=>{ nav(-1) }} />
        <Card>
            <Form ref={referensi} onFinish={finish}>
                <Form.Item>
                    {filename!=='' ?
                        <>
                            {filename===filelama ?
                            <Image height={128} src={Globalset.app_assets+'/news/512/'+info['file']} preview={{src: Globalset.app_assets+'/news/1280/'+info['file'] }} />
                            : <Image height={128} src={Globalset.app_assets+'/tmp/'+filename} /> }
                        </>
                    :<Tag color='orange' icon={<FontAwesomeIcon icon={faInfo} />}>Gambar belum diunggah</Tag> }
                    <br /><br />
                    <Space>
                        <Upload {...props} maxCount={1}>
                            <Button size="small"><><FontAwesomeIcon icon={faCamera} /> Ganti Gambar</></Button>
                        </Upload>
                        {filename!=='' ?
                        <Popconfirm title="Konfirmasi hapus gambar ?" onConfirm={hapusgambar}><Button size="small"><FontAwesomeIcon icon={faTrash} /></Button></Popconfirm>
                        : <></> }
                    </Space>
                </Form.Item>
                <Form.Item label='Status'>
                    <Switch checkedChildren='Aktif' unCheckedChildren='Tidak Aktif' checked={aktif} onChange={e=>{ setAktif(e); }} />
                </Form.Item>
                <Form.Item label='Judul' name='judul'>
                    <Input />
                </Form.Item>
                <Form.Item label='Video Link Youtube' name='youtube'>
                    <Input addonBefore='https://www.youtube.com/watch?v=' placeholder="kode" />
                </Form.Item>
                <Form.Item>
                    <Editor initialValue={isikonten} onInit={(evt, editor)=> isi.current = editor } apiKey={Globalset.app_key_tinymce} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={lod}>Simpan</Button>
                </Form.Item>
            </Form>
            
        </Card>
        </>
    );
}

export default NewsInfoView;