import { Button, Card, DatePicker, Form, Input, message, PageHeader, Select } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Globalset from "../Globalset";
import moment from "moment";
import { Editor } from "@tinymce/tinymce-react";

const { Option } = Select;

function EventInfoView(){

    const nav = useNavigate();
    const referensi = useRef();
    const param = useParams();
    const [lod, setLod] = useState(false);
    const [kategori, setKategori] = useState([]);
    const kategori_list = kategori.map(e=>(
        <Option key={e['id']}>{e['nama']}</Option>
    ));
    const isi = useRef();
    const [isikonten, setIsikontent] = useState(''); 

    useEffect(()=>{
        axios(Globalset.app_url+'/event_category', Globalset.app_authentic).then(res=>{
            setKategori(res.data);

            axios(Globalset.app_url+'/event_info?id='+param.id, Globalset.app_authentic).then(res2=>{
                //console.log(res.data);
                let json = res2.data;
                referensi.current.setFieldsValue({
                    judul: json['judul'],
                    idkategori: json['idkategori'],
                    tanggal: moment(json['tanggal'], 'YYYY-MM-DD'),
                    lokasi: json['lokasi']
                });
                setIsikontent(json['isi']);
            });
        });
    }, []);

    const finish = e => {
        e['id'] = param.id;
        e['isi'] = isi.current.getContent();
        e['tanggal'] = e['tanggal'].format('YYYY-MM-DD');
        setLod(true);
        axios.post(Globalset.app_url+'/event_edit', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            if(res.data==='success'){
                message.success('Ubah Agenda Kegiatan berhasil !');
                setTimeout(()=>{
                    nav('/event');
                }, 1000);
            } else {
                message.error('Terjadi gangguan !');
            }
        });
    }

    return(
        <>
            <PageHeader title='Ubah Agenda Kegiatan' onBack={()=>{ nav(-1) }} />
            <Card>
                <Form layout="vertical" ref={referensi} onFinish={finish}>
                    <div className="row">
                        <div className="col-md-6">
                            <Form.Item label='Judul' name='judul' rules={[{required: true}]}>
                                <Input />
                            </Form.Item>
                        </div>
                        <div className="col-md-6">
                            <Form.Item label='Kategori' name='idkategori' rules={[{required: true}]}>
                                <Select>{kategori_list}</Select>
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item label='Tanggal' name='tanggal'>
                        <DatePicker style={{width: '100%'}} /> 
                    </Form.Item>
                    <Form.Item label='Lokasi' name='lokasi'>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Editor initialValue={isikonten} onInit={(evt, editor)=> isi.current = editor } apiKey={Globalset.app_key_tinymce} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={lod}>Simpan</Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    );
}

export default EventInfoView;