import { Button, Card, Divider, Form, Input, message, PageHeader } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import Globalset from "../Globalset";

function BaseView(){

    const ref = useRef();
    const finish = e => {
        axios.post(Globalset.app_url+'/web_update', serialize(e), Globalset.app_authentic).then(res=>{
            message.success('Data berhasil terbaru !');
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/web', Globalset.app_authentic).then(res=>{
            
            let json = res.data;
            
            ref.current.setFieldsValue({
                'nama_panjang':json['nama_panjang'],
                'nama':json['nama'],
                'telp':json['telp'],
                'email':json['email'],
                'alamat':json['alamat'],
                'facebook':json['facebook'],
                'instagram':json['instagram'],
                'twitter':json['twitter'],
                'linkedin':json['linkedin'],
            });   
        });
    }, []);

    return(
        <>
        <PageHeader title="Informasi Dasar Website" />
        <Card>
            <Form layout="vertical" ref={ref} onFinish={finish}>
                <Form.Item label='Nama Website Panjang' name='nama_panjang'>
                    <Input />
                </Form.Item>
                <Form.Item label='Nama Website Singkat' name='nama'>
                    <Input />
                </Form.Item>
                <Form.Item label='Telepon' name='telp'>
                    <Input />
                </Form.Item>
                <Form.Item label='Email' name='email'>
                    <Input />
                </Form.Item>
                <Form.Item label='Alamat' name='alamat'>
                    <Input.TextArea />
                </Form.Item>
                <Divider dashed />
                <Form.Item label='Facebook' name='facebook'>
                    <Input />
                </Form.Item>
                <Form.Item label='Instagram' name='instagram'>
                    <Input />
                </Form.Item>
                <Form.Item label='Twitter' name='twitter'>
                    <Input />
                </Form.Item>
                <Form.Item label='LinkedIn' name='linkedin'>
                    <Input />
                </Form.Item>
                <Form.Item>
                    <Button htmlType="submit" type="primary">Simpan</Button>
                </Form.Item>
                
            </Form>
        </Card>
        </>
    );
}

export default BaseView;