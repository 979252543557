import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import Globalset from "../Globalset";

const { PageHeader, Card, Button, Space, message } = require("antd");

function AboutView(){

    const nilai = useRef();
    const [nilaiKontent, setNilaiKontent] = useState('');

    const finish = () => {
        axios.post(Globalset.app_url+'/template_update', serialize({id: 1, nilai: nilai.current.getContent()}), Globalset.app_authentic).then(res=>{
            message.success('Data tersimpan berhasil !');
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/template_info?id=1', Globalset.app_authentic).then(res=>{
            setNilaiKontent(res.data['nilai']);
        });
    }, []);

    return(
        <>
        <PageHeader title='Tentang / Profil Hakli' />
        <Card>
            <Space direction="vertical" style={{width: '100%'}}>
                <Editor initialValue={nilaiKontent} onInit={(evt, editor)=> nilai.current = editor } apiKey={Globalset.app_key_tinymce} />
                <Button type="primary" onClick={finish}>Simpan</Button>
            </Space>
        </Card>
        </>
    );
}

export default AboutView;