import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, DatePicker, Form, Image, Input, message, PageHeader, Select, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionService } from "redux-react-session";
import Globalset from "../Globalset";
const { Option } = Select;

function EventAddView(){

    const [user, setUser] = useState({});
    const nav = useNavigate();
    const [kategori, setKategori] = useState([]);
    const kategori_list = kategori.map(e=>(
        <Option key={e['id']}>{e['nama']}</Option>
    ));

    const isi = useRef();
    const [lod, setLod] = useState(false);
    const [file, setFile] = useState('');

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setFile(info.file.response);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const finish = e => {
        e['idcreate'] = user['id'];
        e['isi'] = isi.current.getContent();
        e['file'] = file;
        e['tanggal'] = e['tanggal'].format('YYYY-MM-DD');
        setLod(true);
        axios.post(Globalset.app_url+'/event_add', serialize(e), Globalset.app_authentic).then(res=>{
            setLod(false);
            if(res.data==='success'){
                message.success('Buat Agenda Kegiatan berhasil !');
                setTimeout(()=>{
                    nav('/event');
                }, 1000);
            } else {
                message.error('Terjadi kesalahan !');
            }
        });
    }

    useEffect(()=>{

        sessionService.loadUser().then(e=>{
            setUser(e);
        });

        axios(Globalset.app_url+'/event_category', Globalset.app_authentic).then(res=>{
            setKategori(res.data);
        });
    }, []);

    return(
        <>
        <PageHeader title='Buat Agenda Kegiatan' onBack={()=>{ nav(-1) }} />
        <Card>
            <Form layout="vertical" onFinish={finish}>
                <Form.Item>
                    {file!=='' ? <><Image height={128} src={Globalset.app_assets+'/tmp/'+file} /><br /></> : null }
                    <Upload {...props} maxCount={1}>
                        <Button size='small'>Pilih Foto</Button>
                    </Upload>
                </Form.Item>
                <div className="row">
                    <div className="col-md-6">
                        <Form.Item label='Judul' name='judul' rules={[{required: true}]}>
                            <Input />
                        </Form.Item>
                    </div>
                    <div className="col-md-6">
                        <Form.Item label='Kategori' name='idkategori' rules={[{required: true}]}>
                            <Select>{kategori_list}</Select>
                        </Form.Item>
                    </div>
                </div>
                <Form.Item label='Tanggal' name='tanggal' rules={[{required: true}]}>
                    <DatePicker  style={{width: '100%'}} />
                </Form.Item>
                <Form.Item label='Lokasi' name='lokasi'>
                    <Input.TextArea />
                </Form.Item>
                <Form.Item label='Isi Kegiatan'>
                    <Editor onInit={(evt, editor)=> isi.current = editor } apiKey={Globalset.app_key_tinymce} />
                </Form.Item>
                <Form.Item>
                    <Button loading={lod} type="primary" htmlType="submit">Simpan</Button>
                </Form.Item>
            </Form>
        </Card>
        </>
    );
}

export default EventAddView;