import { Editor } from "@tinymce/tinymce-react";
import { Button, Card, message, PageHeader, Space } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useRef, useState } from "react";
import Globalset from "../Globalset";

function AboutVisionView(){

    const info = useRef();
    const [nilaiKontent, setInfokonten] = useState('');
    const finish = e => {
        axios.post(Globalset.app_url+'/template_update', serialize({id: 3, nilai: info.current.getContent()}), Globalset.app_authentic).then(res=>{
            message.success('Data berhasil tersimpan !');
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/template_info?id=3', Globalset.app_authentic).then(res=>{
            setInfokonten(res.data['nilai']);
        });
    }, []);

    return(
        <>
        <PageHeader title='Visi dan Misi' />
        <Card>
            <Space direction="vertical" style={{width: '100%'}}>
                <Editor initialValue={nilaiKontent} onInit={(evt, editor)=> info.current = editor } apiKey={Globalset.app_key_tinymce} />
                <Button type="primary" onClick={finish}>Simpan</Button>
            </Space>
        </Card>
        </>
    );
}

export default AboutVisionView;