import { faAdd, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Image, message, PageHeader, Popconfirm, Popover, Upload } from "antd";
import axios from "axios";
import { serialize } from "object-to-formdata";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Globalset from "../Globalset";

function EventImageView(){

    const param = useParams();
    const nav = useNavigate();
    const [info, setInfo] = useState('');
    const [gambar, setGambar] = useState([]);
    const gambar_list = gambar.map(e=>(
        <Popover content={<Popconfirm title='Konfirmasi hapus' onConfirm={()=>{ del(e['id']) }}><Button><><FontAwesomeIcon icon={faTrash} /> Hapus</></Button></Popconfirm>}>
            <Image height={128} src={Globalset.app_assets+'/event/512/'+e['file']} preview={{src: Globalset.app_assets+'/event/1280/'+e['file']}} />
        </Popover>
    ));

    const kirim = newfile => {
        axios.post(Globalset.app_url+'/event_image_add', serialize({id: param.id, file: newfile}), Globalset.app_authentic).then(res=>{
            axios(Globalset.app_url+'/event_image?id='+param.id, Globalset.app_authentic).then(res2=>{
                setGambar(res2.data);
            });
        });
    }

    const props = {
        name: 'userfile',
        action: Globalset.app_url+'/upload',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
                kirim(info.file.response);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const del = e => {
        axios.post(Globalset.app_url+'/event_image_del', serialize({'id':e}), Globalset.app_authentic).then(res=>{
            console.log(res);
            fill();
        });
    }

    const fill = () => {
        axios(Globalset.app_url+'/event_image?id='+param.id, Globalset.app_authentic).then(res2=>{
            setGambar(res2.data);
        });
    }

    useEffect(()=>{
        axios(Globalset.app_url+'/event_info?id='+param.id, Globalset.app_authentic).then(res=>{
            setInfo(res.data);
            fill();
        });
    }, []);

    return(
        <>
        <PageHeader title='Foto Agenda Kegiatan' onBack={()=>{ nav(-1) }} />
        <Card title={info['judul']}>
            {gambar_list}
            <br /><br />
            <Upload {...props} maxCount={1}>
                <Button icon={<FontAwesomeIcon icon={faAdd} />}>Tambah Foto</Button>
            </Upload>
            
        </Card>
        </>
    );
}

export default EventImageView;